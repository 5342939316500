<template>
  <div class="flex flex-wrap">
    <div class="masonry-content p-5 relative w-full">
      <div class="flex flex-col">
        <div class="flex flex-wrap -my-2 -mx-5">
          <!-- Job Title -->
          <div class="flex flex-col w-full my-2 px-5">
            <label class="uppercase font-semibold text-sm">Job Title</label>
            <input class="bge-input bge-input-spacing rounded" type="text" v-model="person.job_title" />
          </div>

          <!-- Title -->
          <div class="flex flex-col w-full my-2 px-5">
            <label class="uppercase font-semibold text-sm">Title</label>
            <input class="bge-input bge-input-spacing rounded" type="text" v-model="person.title" />
          </div>

          <!-- First Name -->
          <div class="flex flex-col w-full my-2 px-5">
            <label class="uppercase font-semibold text-sm">First Name <span class="text-red-500">*</span></label>
            <input class="bge-input bge-input-spacing rounded" type="text" v-model="person.firstname" required />
          </div>

          <!-- Last Name -->
          <div class="flex flex-col w-full my-2 px-5">
            <label class="uppercase font-semibold text-sm">Last Name <span class="text-red-500">*</span></label>
            <input class="bge-input bge-input-spacing rounded" type="text" v-model="person.lastname" required />
          </div>

          <!-- Email -->
          <div class="flex flex-col w-full my-2 px-5">
            <label class="uppercase font-semibold text-sm">Email</label>
            <input class="bge-input bge-input-spacing rounded" type="email" v-model="person.email" required />
          </div>

          <!-- Telephone -->
          <div class="flex flex-col w-full my-2 px-5">
            <label class="uppercase font-semibold text-sm">Telephone</label>
            <input class="bge-input bge-input-spacing rounded" type="text" v-model="person.telephone" />
          </div>

          <!-- Mobile -->
          <div class="flex flex-col w-full my-2 px-5">
            <label class="uppercase font-semibold text-sm">Mobile</label>
            <input class="bge-input bge-input-spacing rounded" type="text" v-model="person.mobile" />
          </div>

          <!-- Default Contact as Combo Box -->
          <div class="flex flex-col w-full my-2 px-5">
            <label class="uppercase font-semibold text-sm">Default Contact <span class="text-red-500">*</span></label>
            <select v-model="person.is_default" class="bge-input bge-select rounded" required>
              <option :value="false">No</option>
              <option :value="true">Yes</option>
            </select>
          </div>
        </div>
      </div>

      <div class="flex justify-end mt-5">
        <AppButton color="blue" @click="isEditMode ? updatePerson() : createPerson()" label="Save" :icon="true">
          <template v-slot:icon>
            <Icon iconType="save" class="mr-2" />
          </template>
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script>
import notificationMixin from "@/mixins/notificationMixin";
import Icon from "@/components/Icon.vue";
import AppButton from "@/components/Button.vue";

export default {
  name: "AddPerson",
  mixins: [notificationMixin],
  components: {
    AppButton,
    Icon,
  },
  props: {
    initial_person: {
      type: Object,
      default: () => ({}),
    },
    contact_id: {
      type: String,
      required: true,
    },
    person_id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      person: {},
      isEditMode: !!this.person_id,
    };
  },
  async mounted() {
    if (this.initial_person) {
      this.person = {...this.initial_person};
    }
  },
  methods: {
    validateRequiredFields() {
      const requiredFields = [
        {field: this.person.firstname, name: "First Name"},
        {field: this.person.lastname, name: "Last Name"},
      ];

      for (const field of requiredFields) {
        if (!field.field) {
          this.notifyError(`${field.name} is required.`);
          return false;
        }
      }
      return true;
    },
    async createPerson() {
      if (!this.validateRequiredFields()) return;

      try {
        await this.ContactService.createPerson(this.contact_id, this.person);
        this.notifySuccess("Person added successfully");
        this.$emit("refreshContact");
      } catch (error) {
        this.notifyError("Couldn't add Person");
        console.error(error);
      }
    },
    async updatePerson() {
      if (!this.validateRequiredFields()) return;

      try {
        await this.ContactService.updatePerson(this.contact_id, this.person_id, this.person);
        this.notifySuccess("Person updated successfully");
        this.$emit("refreshContact");
      } catch (error) {
        this.notifyError("Couldn't update Person");
        console.error(error);
      }
    },
  },
};
</script>
